<template>
  <section class="main">
    <div

      style="margin-top: 60px; width: 50%;"
    >
      <h4
        style="color: #262E6C; margin-top: 20px; font-weight: 400;"
      > {{ $t('Material Variant Order History Tracking') }}</h4>
      <div
        class="tt"
        style=" padding-top: 16px"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
              <th>
                {{ $t('Message') }}
              </th>
              <th>
                {{ $t('Executor Name') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for=" ( item ,index) in getHistoryTracking"
              :key="index"
              class="hover-row"
              style="cursor:pointer;"
            >
              <td>
                {{ item.state }}
              </td>
              <td>
                {{ createdDateModified(item.created) }}
              </td>
              <td>
                {{ item.message }}
              </td>
              <td>
                {{ item.fullName }}
              </td>
            </tr>
          </tbody>
        <!-- <tbody v-else>
              <tr>
                <td
                  colspan="4"
                  style="padding: 9px; text-align: left"
                >
                  No Material was found!
                </td>
              </tr>
            </tbody> -->
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getHistoryTracking']),
  },
  watch: {

  },
  created() {
  },
  methods: {
    ...mapActions(['loadOrderedMaterials', 'loadOrderItems']),
    createdDateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
  },
};
</script>

      <style scoped lang="scss">

      .team_table td {
        padding: 16px 9px;
      }
      .hover-row:hover {
        background-color: #f5f5f5; /* Set your desired background color here */
      }
    .mats-listing__wrapper {
      width: 600px !important;

      .tt {
        .vgt-table {
          th.sortable button {
            display: none !important;
          }
        }
      }

      .mats-listing {
        margin-top: 0;
      }
    }

    .button {
      margin-bottom: 15px !important;
      width: 195px;
    }

    // .team_table .tr{
    //   border-radius: 10px 10px 10px 10px !important;
    // }
    tr:last-child {
      border-bottom: none !important;
    }

    @media screen and (max-width: 1723px) {
      .mats-listing__wrapper {
        width: 400px !important;

        div {
          button {
            width: auto;
            height: 36px;
          }

          input {
            width: 160px;
          }

        }

        .table__search-input {
          padding-left: 0px;
          margin-left: 0px !important;
        }
      }

    }

    @media screen and (max-width: 1400px) {
      .mats-listing__wrapper {
        width: 100% !important;
      }
    }


    .dropdrop::-webkit-scrollbar {
      display: none;

    }

    .dropdrop {
      display: inline-block;
      overflow: auto;
      position: absolute;
      background: white;
      width: 227px;
      box-shadow: none;
    }

    input[name="search-box"] {
      display: block;
      max-width: 500px;
      width: 95%;
      padding: 0.5rem 1rem;
      padding-left: 25px;
    }

    .black {
      position: relative;
    }

    .black input {
      border: none;
      box-shadow: none;
    }

    .search-item {
      padding: 5px;
      max-width: 500px;
      width: 100%;
      font-weight: 400;
      color: #82868c;
      font-size: 1rem;
      border-bottom: 1px solid #e8e8e8;
    }

    .search-item:last-child {
      border-bottom: none;
    }

    .search-item:hover {
      background: #82868c;
      color: white;
    }

    .search-item hr {
      color: lightgray;
      border-top: none;
      margin: -1.3rem 0 0.5rem 0;
    }

    .is-active {
      background-color: #dedede;
    }
      </style>
